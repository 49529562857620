
























































































































































































































import {
  CatalogueOrderMaterial,
  CatalogueSizeOption,
  CreateOrderDetailInput,
  CreateOrderInput,
  MasterDataViewModel,
  ProductViewModel
} from '@/api/api';
import {
  ProductAction,
  ProductState,
  PRODUCT_STATE_NAMESPACE
} from '@/store/product/product.module-types';
import {
  SETTING_STATE_NAMESPACE,
  SettingState
} from '@/store/setting/setting.module-types';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { formatCurrency, convertCurrencyToNumber } from '@/utility/utils';
import {
  BTable,
  BTh,
  BTd,
  BTr,
  BTfoot,
  BThead,
  BTbody,
  BFormInput,
  BButton,
  BFormCheckbox,
  BRow,
  BCol
} from 'bootstrap-vue';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapActions, mapState } from 'vuex';
import vSelect from 'vue-select';
import DepositInputModal from '../orderList/modals/DepositInputModal.vue';

const OrderDetailTableProps = Vue.extend({
  props: {
    orderId: Number,
    order: CreateOrderInput,
    catalogueProductItems: {
      type: Array as PropType<CatalogueOrderMaterial[]>,
      default: () => []
    }
  }
});
// Define the component in class-style
@Component({
  components: {
    BTable,
    BTh,
    BTd,
    BTr,
    BTfoot,
    BThead,
    BTbody,
    BFormInput,
    BButton,
    BFormCheckbox,
    BRow,
    BCol,
    vSelect,
    DepositInputModal
  },
  computed: {
    ...mapState(PRODUCT_STATE_NAMESPACE, [ProductState.products]),
    ...mapState(SETTING_STATE_NAMESPACE, [SettingState.masterData])
  },
  methods: {
    ...mapActions(PRODUCT_STATE_NAMESPACE, [ProductAction.fetchProducts])
  }
})
export default class OrderDetailTable extends OrderDetailTableProps {
  //Vuex
  products!: ProductViewModel[];
  fetchProducts!: () => Promise<void>;
  masterData!: MasterDataViewModel;

  $refs!: {
    depositInputModalRef: any;
  };

  // Class properties will be component data
  fields = [
    {
      key: 'index',
      label: this.$t('index'),
      thClass: 'th-color',
      thStyle: { width: '1%' }
    },
    {
      key: 'serviceId',
      label: this.$t('Service'),
      thClass: 'th-color',
      width: '15%'
    },
    // {
    //   key: 'polymeCoverTypeName',
    //   label: this.$t('PolymeCoverDetaillbl'),
    //   thClass: 'th-color'
    // },
    // {
    //   key: 'processingTypeName',
    //   label: this.$t('Processing'),
    //   thClass: 'th-color'
    // },
    // {
    //   key: 'processingTypeName2',
    //   label: this.$t('Machining2'),
    //   thClass: 'th-color',
    //   optional: true
    // },
    {
      key: 'note',
      label: this.$t('Note'),
      thClass: 'th-color',
      thStyle: { width: '15%' }
    },
    {
      key: 'width',
      label: this.$t('Widthlbl'),
      thClass: 'th-color'
    },
    {
      key: 'height',
      label: this.$t('Heightlbl'),
      thClass: 'th-color'
    },
    {
      key: 'quantity',
      label: this.$t('Quantitylbl'),
      thClass: 'th-color'
    },
    {
      key: 'area',
      label: this.$t('TotalArealbl'),
      thClass: 'th-color'
    },
    // {
    //   key: 'totalArea',
    //   label: this.$t('TotalArealbl'),
    //   thClass: 'th-color'
    // },
    {
      key: 'pricePerUnit',
      label: this.$t('PricePerUnit'),
      thClass: 'th-color',
      thStyle: { width: '7%' }
    },
    {
      key: 'totalPrice',
      label: this.$t('Price'),
      thClass: 'th-color',
      thStyle: { width: '10%' }
    },
    // {
    //   key: 'VAT',
    //   label: 'VAT',
    //   thClass: 'th-color'
    // },
    // {
    //   key: 'finalPrice',
    //   label: this.$t('FinalPrice'),
    //   thClass: 'th-color'
    // },
    {
      key: 'Actions',
      label: '',
      thClass: 'th-color',
      thStyle: { width: '5%' }
    }
  ];
  catalogueSizes: CatalogueSizeOption[] = [];
  vatOptions = [8, 10];

  get colSpanNumber(): number {
    return 7;
  }

  get vATAmount(): number {
    let vatAmount = 0;
    this.order?.createOrderDetails.forEach(
      (s) => (vatAmount += s.includeVAT === true ? s.vatAmount : 0)
    );

    return vatAmount;
  }

  isNewOrderDetailItem(data) {
    return !data.item.id || data.item.id == '';
  }

  @Watch('order', { deep: true })
  totalPrice(): number {
    let price = 0;
    this.order?.createOrderDetails.forEach(
      (s) =>
        (price += convertCurrencyToNumber(
          this.getFinalPrice({ item: { ...s } })
        ))
    );
    const vatAmount =
      (convertCurrencyToNumber(this.order.vatAmount) / 100) * price;
    const total =
      convertCurrencyToNumber(this.order.shippingFee) +
      vatAmount +
      price -
      convertCurrencyToNumber(this.order.discountAmount);
    return total < 0 ? 0 : total;
  }

  get remainAmount(): number {
    return (
      this.totalPrice() - convertCurrencyToNumber(this.order.depositAmount)
    );
  }

  get orderDetailItems(): CreateOrderDetailInput[] {
    return this.order.createOrderDetails;
  }

  // Methods will be component methods
  formatCurrency = formatCurrency;

  getServiceName(orderDetail: CreateOrderDetailInput) {
    const productName = this.products.find(
      (s) => s.id === (orderDetail.serviceId ?? 0)
    )?.name;
    if (
      orderDetail.categoryId &&
      orderDetail.categoryId === this.masterData.catalogueCategoryId
    ) {
      const productItem =
        this.catalogueProductItems?.find(
          (s) => s.catalogueProductItemId == orderDetail.catalogueProductItemId
        )?.catalogueProductItemName ?? '';

      const size =
        this.catalogueProductItems
          .flatMap((s) => s.sizeOptions ?? [])
          .find((s) => s.width == orderDetail.width)?.name ?? '';

      return `<div><div class='row mx-0'><div class='col-sm-7 px-0'>${productName} </div><div class='col-sm-5 px-0'>- ${orderDetail.totalPage} Trang</div></div>
              <div class='row mx-0'><div class='col-sm-7 px-0'>- ${productItem} </div> <div class='col-sm-5 px-0'>- ${size} </div></div>
              <div class='row mx-0'><div class='col-sm-7 px-0'>-  ${orderDetail.polymeCoverTypeName}</div></div></div>`;
    }

    if (
      orderDetail.processingSettings &&
      orderDetail.processingSettings.length > 0
    ) {
      let productNameCell = `<div><div class='row mx-0'><div class='col-sm-6 px-0'>${productName}</div>`;

      for (
        let index = 0;
        index < orderDetail.processingSettings.length;
        index++
      ) {
        const element = orderDetail.processingSettings[index];
        if (element) {
          productNameCell += `<div class='col-sm-6 px-0'>- ${element.name} </div>`;
        }
      }
      return productNameCell + '</div>';
    }

    return `<div>
              <div class='row mx-0'>
                <div class='col-sm-6 px-0'>
                  ${productName}</div>
                <div class='col-sm-6 px-0'>
              ${
                orderDetail.polymeCoverTypeName
                  ? '- ' + orderDetail.polymeCoverTypeName
                  : ''
              }</div>
              <div class='col-sm-6 px-0'>
              ${
                orderDetail.processingTypeName
                  ? '- ' + orderDetail.processingTypeName
                  : ''
              }</div></div>
              ${
                orderDetail.processingTypeName2
                  ? '- ' + orderDetail.processingTypeName2
                  : ''
              }</div>`;
  }

  getFinalPrice(data) {
    if (data) {
      return formatCurrency(data.item.totalPrice);
    }
  }

  onEdit(data) {
    this.$emit('onEditOrderDetail', data);
  }

  onDelete(data) {
    const removeIndexItem = data.index;
    this.order.createOrderDetails.splice(removeIndexItem, 1);
  }

  isCatalogueItem(orderDetail: CreateOrderDetailInput) {
    return orderDetail.categoryId === this.masterData.catalogueCategoryId;
  }

  validate() {
    const inputValue = convertCurrencyToNumber(this.order.vatAmount);
    if (isNaN(inputValue) || inputValue < 0 || inputValue > 100) {
      this.order.vatAmount = 0;
    }
  }

  async onDepositClick() {
    if (this.orderId && this.orderId > 0) {
      const result = await this.$refs.depositInputModalRef.show(this.orderId);
      console.log(result);
      if (result > 0) {
        this.order.depositAmount = result;
      }
    }
  }
}
